const config = {
  graphQL: {
    baseUrl: import.meta.env.VITE_GRAPHQL_BASE_URL || '/graphql',
  },
  dw: {
    baseUrl: 'https://www.deutsche-welle.com',
    mobileUrl: 'https://m.deutsche-welle.com',
  },
  // static urls used on download page. Will be replaced with webapi based solution (LG-719).
  download: {
    videoBaseUrl: 'https://tvdownloaddw-a.akamaihd.net/downloads/',
    videoMp4Path: 'Events/mp4/',
    videoFlvPath: 'dwtv_video/flv/',
    audioBaseUrl: 'https://radiodownloaddw-a.akamaihd.net/downloads/Events/dwelle/',
  },
};

export default config;
